// @flow
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILED,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAILED,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILED,
    BALANCE_REQUEST,
    BALANCE_REQUEST_SUCCESS,
    BALANCE_REQUEST_FAILED,
    SIMULATE_USER,
    SIMULATE_USER_SUCCESS,
    SIMULATE_USER_FAILED,
    TOGGLE_ACCOUNT_FAILED,
    TOGGLE_ACCOUNT,
    TOGGLE_ACCOUNT_SUCCESS,
    TOGGLE_PROFILE,
    TOGGLE_PROFILE_SUCCESS,
    TOGGLE_PROFILE_FAILED,
} from './constants';

type AuthAction = { type: string, payload: {} | string };

export const loginUser = (email, password, history): AuthAction => ({
    type: LOGIN_USER,
    payload: { email, password },
});

export const loginUserSuccess = (user: string): AuthAction => ({
    type: LOGIN_USER_SUCCESS,
    payload: user,
});

export const loginUserFailed = (error: string): AuthAction => ({
    type: LOGIN_USER_FAILED,
    payload: error,
});

export const registerUser = (fullname: string, email: string, password: string): AuthAction => ({
    type: REGISTER_USER,
    payload: { fullname, email, password },
});

export const registerUserSuccess = (user: {}): AuthAction => ({
    type: REGISTER_USER_SUCCESS,
    payload: user,
});

export const registerUserFailed = (error: string): AuthAction => ({
    type: REGISTER_USER_FAILED,
    payload: error,
});

export const logoutUser = (history: any): AuthAction => ({
    type: LOGOUT_USER,
    payload: { history },
});

export const balanceRequest = (identification: string): AuthAction => ({
    type: BALANCE_REQUEST,
    payload: { identification },
});

export const balanceRequestSuccess = (balanceRequestStatus: string): AuthAction => ({
    type: BALANCE_REQUEST_SUCCESS,
    payload: { balanceRequestStatus },
});

export const balanceRequestFailed = (balanceError: string): AuthAction => ({
    type: BALANCE_REQUEST_FAILED,
    payload: balanceError,
});

export const forgetPassword = (email: string, history): AuthAction => ({
    type: FORGET_PASSWORD,
    payload: { email, history },
});

export const forgetPasswordSuccess = (passwordResetStatus: string): AuthAction => ({
    type: FORGET_PASSWORD_SUCCESS,
    payload: passwordResetStatus,
});

export const forgetPasswordFailed = (error: string): AuthAction => ({
    type: FORGET_PASSWORD_FAILED,
    payload: error,
});

export const resetPassword = (password: string, token: string, history): AuthAction => ({
    type: RESET_PASSWORD,
    payload: { password, token, history },
});

export const resetPasswordSuccess = (passwordResetStatus: string): AuthAction => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: passwordResetStatus,
});

export const resetPasswordFailed = (error: string): AuthAction => ({
    type: RESET_PASSWORD_FAILED,
    payload: error,
});

export const simulateUser = (id, token): AuthAction => ({
    type: SIMULATE_USER,
    payload: { id, token },
});

export const simulateUserSuccess = (user: string): AuthAction => ({
    type: SIMULATE_USER_SUCCESS,
    payload: user,
});

export const simulateUserFailed = (error: string): AuthAction => ({
    type: SIMULATE_USER_FAILED,
    payload: error,
});

export const toggleAccount = (user, organization): AuthAction => ({
    type: TOGGLE_ACCOUNT,
    payload: { user, organization },
});

export const toggleAccountSuccess = (user: string): AuthAction => ({
    type: TOGGLE_ACCOUNT_SUCCESS,
    payload: user,
});

export const toggleAccountFailed = (error: string): AuthAction => ({
    type: TOGGLE_ACCOUNT_FAILED,
    payload: error,
});

export const toggleProfile = (user): AuthAction => ({
    type: TOGGLE_PROFILE,
    payload: { user },
});

export const toggleProfileSuccess = (user: string): AuthAction => ({
    type: TOGGLE_PROFILE_SUCCESS,
    payload: user,
});

export const toggleProfileFailed = (error: string): AuthAction => ({
    type: TOGGLE_PROFILE_FAILED,
    payload: error,
});